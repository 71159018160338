import { ReactNode, useContext, useMemo } from 'react'
import { ResultsData } from '@sh24/admin-api-js'
import { ButtonLink } from '@sh24/ui-components'
import type { ConditionalTextBlock as ConditionalTextBlockType } from '@sh24/contentful-service'
import AccountContext from '../../../../contexts/account-context'
import ResultsPanelOrderDisplay from '../../../ResultCards/results-panel-order-display'
import InlineLink from '../../../InlineLink/inline-link'
import ensureResultsLoaded from '../../../../utils/ensure-results-loaded'
import RichTextDocumentContext from '../../../../contexts/rich-text-document-context'
import conditionalTextBlockEntriesToReactComponents from '../../../../utils/conditional-text-block-entries-to-react-components'
import PartnerNotificationOfferCard from '../../../PartnerNotificationOffer/partner-notification-offer-card'

const Content = ({ children = undefined }: { children?: ReactNode }) => (
  <div className="row row-centered">
    <div className="col col-12 col-md-6 col-lg-4">
      {children}
    </div>
  </div>
)

const currentOrder = (results: ResultsData, orderId: string) => results.orders.find(
  (order) => order.sh24Uid === orderId,
)

const ResultsModule = ({
  modules,
}: {
  modules: ConditionalTextBlockType[],
}) => {
  const { resultsContext, orderId } = useContext(AccountContext)
  const { results, errors } = resultsContext
  ensureResultsLoaded()

  const contextProviderValue = useMemo(
    () => ({ sh24Uid: orderId }),
    [orderId],
  )

  if (errors?.errors) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your results. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
        <p>
          If you have seen this page for longer than 48 hours,{' '}
          <a href="https://sh24.org.uk/contact-us">contact us</a> and let us
          know.
        </p>
        <ButtonLink variation="primary" animation="shiftLeft" iconPosition="left">
          <InlineLink url="/results" text="Back to order history" iconName="arrow-left" iconPosition="left" />
        </ButtonLink>
      </>
    )
  }

  if (!results || !orderId) {
    return (
      <div className="row row-centered">
        <div className="col col-12 col-md-6">
          <p>Loading results...</p>
        </div>
      </div>
    )
  }

  if (!currentOrder(results, orderId)) {
    return (
      <div className="row row-centered">
        <div className="col col-12 col-md-6">
          <p>This order could not be found.</p>
        </div>
      </div>
    )
  }

  const partnerNotificationOffer = currentOrder(results, orderId)?.followOn?.partnerNotificationOffer
  const chlamydiaTreatment = currentOrder(results, orderId)?.followOn?.chlamydiaTreatment

  const partnerNotificationOfferCard = partnerNotificationOffer
    && (!chlamydiaTreatment || chlamydiaTreatment?.status !== 'offered') && (
      <Content>
        <PartnerNotificationOfferCard
          offerToken={partnerNotificationOffer.offerToken}
        />
      </Content>
  )

  return (
    <>
      <Content>
        <ResultsPanelOrderDisplay results={results} orderId={orderId} />
      </Content>
      <Content>
        <div>
          <RichTextDocumentContext.Provider value={contextProviderValue}>
            {conditionalTextBlockEntriesToReactComponents(modules, contextProviderValue)}
          </RichTextDocumentContext.Provider>
        </div>
      </Content>
      {partnerNotificationOfferCard}
    </>
  )
}

export default ResultsModule
